<template>

<app-content :loading="is.loading && !is.initialised">

	<app-content-head title="Play to Win" subtitle="Manage the games that can be won by checking them out." :readonly="$authorised('con/checkout/access', 'read')">

		<app-content-head-actions-item text="Create" icon="create" v-on:click="onCreateClick" />
		<app-content-head-actions-item text="Gift" icon="play" :loading="is.gifting" :disabled="!selected.length" v-on:click="onGiftClick" />
		<app-content-head-actions-item text="Delete" icon="delete" :danger="true" :disabled="!selected.length" v-on:click="onDeleteClick" />

	</app-content-head>

	<app-content-body :loading="is.loading && is.initialised" :is-empty="!collection.length" placeholder="Sorry, no games found.">

		<app-table>

			<app-table-head :layout="layout">

				<app-table-column text="Name" />
				<app-table-column text="Winner" />
				<app-table-column align="center" text="Users" />
				<app-table-column align="center" text="Loans" />
				<app-table-column />

			</app-table-head>

			<app-table-body>

				<app-table-row v-on:click="onEditClick(item.id)" :success="item.winner" :layout="layout" v-for="item in collection" :key="item.id">

					<app-table-cell-text :text="item.name" :subtext="item.reference" />
					<app-table-cell-text :text="item.winner.name" :subtext="'Badge No. ' + item.winner.reference" v-if="item.winner" />
					<app-table-cell-text text="-" v-if="!item.winner" />
					<app-table-cell-text align="center" :text="item.participants" />
					<app-table-cell-text align="center" :text="item.plays" />

					<app-table-cell-select :active="isSelected(item.id)" v-on:click="onSelectToggle(item.id)" />

				</app-table-row>

			</app-table-body>

			<app-table-foot :pagination="pagination" :total="total" />

		</app-table>

	</app-content-body>

</app-content>

</template>

<script>

import mixCollection from '@/mixin/collection'

export default {

	mixins: [mixCollection],

	data: function() {

		return {
			is: {
				gifting: false
			},
			layout: 'auto 200px 80px 80px 24px',
			endpoint: 'convention/checkout/wins',
			live: 'convention/checkout/wins',
			edit: 'Convention.Checkout.Wins.Edit'
		}

	},

	methods: {

		onGiftClick: function() {

			this.is.gifting = true

			this.$api.patch(this.endpoint, {
				index: this.selected
			}).then(function() {

				this.selected = []
				this.is.gifting = false

			}.bind(this))

		}

	}

}

</script>

<style scoped>

</style>